import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  formPost(data: any, route: string) {
    const url = environment.api + route;
    return this.http.post(url, data).toPromise()
      .then( response => {
        return response;
      })
      .catch( error => {
        return error;
      });
  }
}
