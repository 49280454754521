import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(@Inject(DOCUMENT) private dom) { }

  // Creating Canonical URL for seo purposes
  createCanonicalURL() {
    const link = (this.dom.head.querySelector(`link[rel='canonical']`)) as HTMLLinkElement || this.dom.head.appendChild(this.dom.createElement('link'));
    link.rel = 'canonical';
    link.href = this.dom.URL;
  }

}
