import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Shared modules
import { NavigationModule } from '@app/navigation/navigation.module';
import {EmailService} from '@app/services/email.service';
import { SeoService } from '@app/services/seo.service';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    NavigationModule,
    HttpClientModule,
  ],
  declarations: [
      AppComponent
  ],
  providers: [
    EmailService,
    SeoService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
