<header aria-label="header">
  <div class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="/" aria-label="Return to the homepage" role="navigation">
          <img src="{{dulakLogo}}" alt="Dulak Physcial Therapy and Golf logo" role="img" />
        </a>
        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" (click)="navVisible = !navVisible">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" [class.is-flex]="navVisible">
        <div class="navbar-end">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              Services
            </a>
            <div class="navbar-dropdown">
              <a class="navbar-item" routerLink="/services/physical-therapy" (click)="navVisible = false">
                Physical Therapy
              </a>
              <a class="navbar-item" routerLink="/services/golf" (click)="navVisible = false">
                Golf
              </a>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" routerLink="/about" (click)="navVisible = false">
              About
            </a>
            <div class="navbar-dropdown">
              <a class="navbar-item" routerLink="/about/andrew" (click)="navVisible = false">
                Dr. Andrew
              </a>
              <a class="navbar-item" routerLink="/about/matt" (click)="navVisible = false">
                Dr. Matt
              </a>
              <hr class="navbar-divider">
              <a class="navbar-item" routerLink="/blog" (click)="navVisible = false">
                Blog
              </a>
            </div>
          </div>
          <a class="navbar-item" routerLink="/contact" (click)="navVisible = false">
            Contact
          </a>

          <div class="navbar-item">
            <div class="buttons">
              <a class="button is-primary is-outlined" routerLink="/schedule">
                <strong>Request Appointment</strong>
              </a>
              <a class="button is-primary is-outlined" href="tel:4124371798">
                <strong>Call Us</strong>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</header>
