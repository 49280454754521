<footer>
  <div class="container">
    <div class="columns">
      <div class="column">
        <p class="title">Connect</p>
        <ul>
          <li>
            <a href="https://www.facebook.com/DulakPhysicalTherapyandGolf" target="_blank" rel="noopener">
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/dulakpt_and_golf" target="_blank" rel="noopener">
              Instagram
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UC1QnfV53DV9BHA9Zf_r0Lcg" target="_blank" rel="noopener">
              Youtube
            </a>
          </li>
        </ul>
      </div>
      <div class="column">
        <p class="title">EXPLORE</p>
        <ul>
          <li>
            <a routerLink="/services/physical-therapy">
              Physical Therapy
            </a>
          </li>
          <li>
            <a routerLink="/services/golf">
              Golf
            </a>
          </li>
          <li>
            <a routerLink="/about/andrew">
              Dr. Andrew
            </a>
          </li>
          <li>
            <a routerLink="/about/matt">
              Dr. Matt
            </a>
          </li>

          <li>
            <a routerLink="/blog">
              Blog
            </a>
          </li>

        </ul>
      </div>
      <div class="column">
        <p class="title">Contact</p>
        <p class="company-name">Dulak Physical therapy and golf</p>
        <ul>
          <li>
            <a href="tel:(412) 437-1798">
              (412) 437-1798
            </a>
          </li>
          <li>
            <a href="mailto:dulakpt@gmail.com">
              dulakpt@gmail.com
            </a>
          </li>
          <li>
            <a href="https://goo.gl/maps/cHDR2Qv6C5yd7TdeA" target="_blank">
              4075 Alpha Drive, <br />Allison park 15101
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
