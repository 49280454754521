// This module contains all navigational elements of the project

// Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Header and Footer
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

// Other elements

// Third party libraries
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule
    ],
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})

export class NavigationModule {}
