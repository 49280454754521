import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-header *ngIf="pathname !== 'landing-pages'"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="pathname !== 'landing-pages'"></app-footer>
  `
})
export class AppComponent implements OnInit {
  pathname: string;

  ngOnInit() {
    const split = location.pathname.split('/', 4);
    this.pathname = split[1];
  }
}
